import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) { }
  

  updateSeo({
    title = "El Neobanco del sector salud | Denarius",
    description = "Denarius - Tu solución financiera innovadora.",
    url = environment.environmentUrl,
    keywords = "Denarius, neobanco, sector salud, financiación, soluciones financieras",
    image = `${environment.environmentUrl}/assets/images/logo_denarius.png`,
    noIndex = false
  }) {
    const fullTitle = `${title} | Denarius`;
    this.title.setTitle(fullTitle);
    this.meta.updateTag({ name: 'og:title', content: fullTitle });
    this.meta.updateTag({ name: 'twitter:title', content: fullTitle });

    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ name: 'twitter:description', content: description });

    this.meta.updateTag({ property: 'og:url', content: url });

    this.meta.updateTag({ name: 'keywords', content: keywords });

    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ name: 'twitter:image', content: image });
    this.meta.updateTag({ property: 'og:image:width', content: '1200' });
    this.meta.updateTag({ property: 'og:image:height', content: '630' });

    const robotsContent = environment.robots || (noIndex ? 'noindex, nofollow' : 'index, follow');
    this.meta.updateTag({ name: 'robots', content: robotsContent });
  }
}