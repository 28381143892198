export const environment = {
  baseUrl: "https://user.denarius.es",
  production: true,
  environmentUrl: "https://denarius.es",
  userUrl: "https://user.denarius.es",
  coreUrl: "https://core.denarius.es",
  companyUrl: "https://company.denarius.es",
  contactUrl: "https://contact.denariusfinance.es",
  shareHolderUrl:"https://minutesuploader.denarius.es",
  dmsUrl: "https://dms.denarius.es",
  robots: 'index, follow'
};
