<div [ngClass]="isOnboarding ? 'onboarding-page' : 'header_container'">
    <div class="upper-container">
        <div class="logo_container">
            <img src="../../../../assets/images/logos/denarius.svg" alt="" routerLink="/">
        </div>
        <div class="navbar-list">
            <app-nav-bar [linkList]="linkListPublic"></app-nav-bar>
        </div>
        <div *ngIf="!showButtons" class="buttons_container">
            <button class="gold-color-button" routerLink="onboarding/empresas" i18n="@@landing.public.title">Hazte
                cliente</button>
            <button class="gold-stroked-button" routerLink="/empresas/area-privada"><img src="../../../../assets/images/icons/Login.svg" alt=""><p>Acceso</p></button>
        </div>
    </div>
</div>
